import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import CoverImg from "../components/CoverImg"


const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Home" description="Portfolio of Tara Segda" />

      <div className="bg-gray-100 pt-4 pb-12 lg:pb-16">
        <section className="container text-gray-700 body-font">
        <CoverImg />
        {data.appearances && data.appearances.nodes.length > 0 ? (
        <Cards items={data.appearances.nodes} />
      ) : (
        <div className="container">No projects found.</div>
      )}
        </section>
      </div>
      
      
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    appearances: allContentfulAppearance {
      nodes {
        title
        description
        id
        link
        order
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
