import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"


const CoverImg = () => {
  const data = useStaticQuery(graphql`
    query CoverQuery {
      allContentfulCoverImage(limit: 1) {
        nodes {
          alt
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const { image, alt } = data.allContentfulCoverImage.nodes[0];

  return (
    <div className="block object-cover object-center w-full h-full rounded-lg">
      <Img fluid={image.fluid} alt={alt} className="rounded-md shadow-md"/>
    </div>
  )
}

export default CoverImg;
