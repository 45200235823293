import Img from "gatsby-image"
import React from "react"

const Card = props => {
  const { title, link, description, image } = props

  return (
    <div className="bg-white  min-h-full h-full shadow-md rounded-md ">
      <a target="_blank" href={link}>
        {/* <div className="">
          <h1
            className="sm:text-lg text-white font-semibold"
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {title}
          </h1>
          <Img
            fluid={image.fluid}
            alt={title}
            imgStyle={{ objectFit: "contain" }}
          />
        </div> */}
        <div className="h-full text-center p-4 sm:p-5">
          <div className="h-full py-1 flex-col content-center">
            <p className="block text-sm sm:text-base text-gray-700">
              {description}
            </p>
          </div>
        </div>
      </a>
    </div>
  )
}

export default Card
